<template>
  <div class="content">
    <PageHeader :title="$t('generic-str.lbl-open-ticket')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <form @submit.prevent="send()">
              <div class="card-body block-el">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="subject">{{$t('generic-str.subject')}}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      :placeholder="$t('generic-str.subject')"
                      v-model="form.subject"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label for="categoty">{{$t('generic-str.department')}}</label>
                    <select
                      id="categoty"
                      class="form-control"
                      v-model="form.category"
                    >
                      <option selected>{{$t('generic-str.department')}}</option>
                      <option value="support">{{$t('generic-str.supp')}}</option>
                      <option value="billing">{{$t('home.invite-card.select.fin')}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="control-label text-left col-12"
                      >{{$tc('generic-str.lbl-product', 1)}}</label
                    >
                    <select-input
                      label="name"
                      :reduce="(product) => product.name"
                      v-model="form.product_name"
                      :options="products"
                      :clearable="false"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress">{{$tc('generic-str.message', 1)}}</label>
                  <textarea
                    class="form-control"
                    id="inputAddress"
                    :placeholder="`${$tc('generic-str.message', 1)}...`"
                    rows="5"
                    v-model="form.message"
                  />
                </div>
                <div class="form-group row">
                  <label class="control-label text-left col-12"
                    >{{$t('generic-str.attach-file')}}</label
                  >
                  <div v-if="!importing" class="col-md-6">
                    <input
                      ref="uploadedFile"
                      type="file"
                      hidden
                      multiple
                      @change="uploadFile"
                    />
                    <div class="input-group mb-3">
                      <div class="form-control">{{ form.file }}</div>
                      <div class="input-group-append">
                        <button
                          class="btn btn-primary"
                          type="button"
                          @click="openFileUpload"
                        >
                          {{$t('sms.send-msg.tabs.archives.lbl-add-archive')}}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="col-md-6">
                    <div class="row">
                      <div class="col-12">
                        <small>{{$t('sms.send-msg.tabs.archives.lbl-validating')}}</small>
                      </div>
                    </div>
                    <div class="progress">
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        :style="`width: ${uploadProgress}%;`"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {{ uploadProgress }}%
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="row" v-for="(attachment, i) of form.attachments" :key="i">
                      <div class="col-sm-6">
                        <div class="card">
                          <div class="row p10">
                            <div class="col-sm-10" style="padding-top:10px;">
                              <h6>{{attachment.name}}</h6>
                            </div>
                            <div class="col-sm-2">
                              <button class="btn btn-danger w100" type="button" @click="removeFile(i, attachment.id)">
                                <i class="fas fa-times color-white"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light text-right">
                <div class="form-actions">
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        class="btn btn-primary"
                        :class="{
                          'qt-loader qt-loader-mini qt-loader-right': isSending,
                        }"
                        :disabled="isSending"
                      >
                        {{$t('generic-str.lbl-open-ticket-2')}}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import TicketService from '@/services/ticket.service';
import axios from '@/plugins/axios';
import ProductService from '@/services/admin/product.service';
import BucketService from '@/services/bucket.service';
import SelectInput from '@/components/SelectInput.vue';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    SelectInput,
  },
  data() {
    return {
      products: [],
      isSending: false,
      importing: false,
      uploadProgress: 0,
      fileImported: {},
      form: {
        subject: '',
        message: '',
        category: 'support',
        attachments: [],
        product_name: '',
        file: null,
      },
    };
  },
  created() {
    ProductService.getProducts().then(
      (response) => {
        console.log('Ticket');
        this.products = response;
        console.log(response);
      },
      (error) => {
        this.content = error;
      },
    );
  },
  methods: {
    removeFile(i, id) {
      BucketService.deleteFile(id).then(
        () => {},
        (error) => {},
      );
      this.form.attachments.splice(i, 1);
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const files = event.target.files;
      // this.form.file = files[0].name;

      /* reader.onload = () => {
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
      };
      reader.readAsText(file, 'UTF-8'); */
      const filesAr = [];
      files.forEach((file) => {
        filesAr.push(file);
        formData.append('files[]', file);
      });
      console.log(filesAr);
      // formData.append('files[]', filesAr);
      formData.append('role', 'public');
      formData.append('folder', 'ticket');

      Promise.all([
        BucketService.upload(
          formData,
          true,
          this.callBackProgress,
        ),
      ])
        .then(
          (responses) => {
            console.log(responses[0].data);
            responses[0].data.forEach((file) => {
              this.form.attachments.push(file);
            });
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.importing = false;
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    send() {
      if (this.form.subject.length === 0) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('ticket-component.subject'),
          type: 'danger',
        });
        return;
      }
      if (this.form.message.length === 0) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('ticket-component.msg-to-supp'),
          type: 'danger',
        });
        return;
      }
      if (this.form.message.length === 0) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('ticket-component.category'),
          type: 'danger',
        });
        return;
      }

      this.isSending = true;
      TicketService.sendTicket(this.form)
        .then(
          (send) => {
            console.log(send);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-sent'),
              type: 'success',
            });
            this.isSending = false;
            this.form.subject = null;
            this.form.message = null;
            this.$router.push('/support');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
